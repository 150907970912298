<template>
    <div id="carriere" class="page-wrapper page-carriere">
        <!-- Hero -->
        <SmallHero v-if="pageIsLoaded" :hero="content.hero" />

        <!-- Intro Desc -->
        <section class="intro-desc site-max-width center">
            <article class="content loner" v-if="pageIsLoaded">
                <h2 class="title sup-title" data-inview="fadeInUp" data-delay="100">{{ content.introDescription1.surtitre }}</h2>
                <h3 class="subtitle medium-title" data-inview="fadeInUp" data-delay="300"><span v-html="content.introDescription1.titre"></span></h3>
            </article>
            <div class="desc" data-inview="fadeInUp" data-delay="400">
                <p class="small-text">
                    <span v-if="pageIsLoaded" v-html="content.introDescription1.texte"></span>
                </p>
            </div>
        </section>

        <!-- Valeurs -->
        <section id="secteurs" class="secteurs site-max-width" v-if="pageIsLoaded">
            <IntroTitles :block="content.introBlock1" />
            <div class="grid-labels with-text is-bigger">
                <a href="#" class="label" title="" v-for="(item, i) in content.gridIcones" :key="'gi' + i" data-inview="fadeInUp" :data-delay="200 * i">
                    <h3>
                        <span>{{ item.titre }}</span>
                        <!-- <svg height="12" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.7006 1.67238L16.835 6.8057L-4.01896e-07 6.8057L-2.97483e-07 9.19438L16.835 9.19438L11.7006 14.3277L13.3729 16L21.3717 8L13.3728 3.69128e-07L11.7006 1.67238Z" fill="#59BBEB"/>
                        </svg> -->
                    </h3>
                    <p class="regular-text text"><span v-html="item.texte"></span></p>
                </a>
            </div>
        </section>

        <!-- Départements -->
        <section class="departements site-max-width" v-if="pageIsLoaded">
            <IntroTitles :block="content.introBlock2" />
            <div class="imgs-grid">
                <article class="img-item" :class="{ 'is-active': selectedDep === content.gridThumbs[0].image.titre }" @click.prevent="selectDep(content.gridThumbs[0].image.titre)">
                    <img :src="content.gridThumbs[0].image.url" :alt="content.gridThumbs[0].image.titre">
                    <h3 class="small-title title"><span data-inview="fadeInUp" data-delay="200">{{ content.gridThumbs[0].titre }}</span></h3>
                    <svg height="24" viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.9095 14.6257L11.4929 21.0438V0H8.50702V21.0438L2.09037 14.6257L0 16.7161L10 26.7146L20 16.7161L17.9095 14.6257Z" fill="white"/>
                    </svg>
                </article>
                <article class="img-item" :class="{ 'is-active': selectedDep === content.gridThumbs[1].image.titre }" @click.prevent="selectDep(content.gridThumbs[1].image.titre)">
                    <img :src="content.gridThumbs[1].image.url" :alt="content.gridThumbs[1].image.titre">
                    <h3 class="small-title title"><span data-inview="fadeInUp" data-delay="200">{{ content.gridThumbs[1].titre }}</span></h3>
                    <svg height="24" viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.9095 14.6257L11.4929 21.0438V0H8.50702V21.0438L2.09037 14.6257L0 16.7161L10 26.7146L20 16.7161L17.9095 14.6257Z" fill="white"/>
                    </svg>
                </article>
                <article class="img-item" :class="{ 'is-active': selectedDep === content.gridThumbs[2].image.titre }" @click.prevent="selectDep(content.gridThumbs[2].image.titre)">
                    <img :src="content.gridThumbs[2].image.url" :alt="content.gridThumbs[2].image.titre">
                    <h3 class="small-title title"><span data-inview="fadeInUp" data-delay="200">{{ content.gridThumbs[2].titre }}</span></h3>
                    <svg height="24" viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.9095 14.6257L11.4929 21.0438V0H8.50702V21.0438L2.09037 14.6257L0 16.7161L10 26.7146L20 16.7161L17.9095 14.6257Z" fill="white"/>
                    </svg>
                </article>
            </div>
        </section>

        <!-- Postes -->
        <section id="postes" class="postes site-max-width" v-if="pageIsLoaded">
            <h4 class="small-title title" data-inview="fadeInUp" data-delay="100">{{ content.introDescription2.surtitre }}</h4>
            <p class="regular-text text" data-inview="fadeInUp" data-delay="300"><span v-html="content.introDescription2.texte"></span></p>

            <div class="postes-list">
                <a :href="item.lien" target="_blank" rel="noopener noreferrer" class="poste-item" :title="item.titre" v-for="(item, i) in content.jobs" :key="'job-' + i" data-inview="fadeInUp" :data-delay="200 * i">
                    <strong class="title">{{ item.titre }}</strong>
                    <span class="status">{{ item.horaire }}</span>
                </a>
            </div>
        </section>
    </div>
</template>

<script>
import { initInViewAnimations } from '@/plugins/InViewAnimations'
import { mapGetters, mapState } from 'vuex'

import SmallHero from '@/components/SmallHero'
import IntroTitles from '@/components/IntroTitles'

export default {
    name: 'Carrière',

    components: {
        SmallHero,
        IntroTitles,
    },

    data() {
        return {
            selectedDep: ''
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    initInViewAnimations()
                }
            },
            immediate: true,
        },
    },

    mounted () {

    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        selectDep(dep) {
            this.selectedDep = dep

            const $zone = document.querySelector('#postes')
            const topPos = $zone.getBoundingClientRect().top + window.scrollY

            // this changes the scrolling behavior to "smooth"
            window.scroll({
                top: topPos - 90,
                behavior: "smooth"
            })
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
