<template>
    <header class="small-hero">
        <article class="content">
            <h2 class="title sup-title" data-inview="fadeInUp" data-delay="100">{{ hero.surtitre }}</h2>
            <h1 class="subtitle big-title" data-inview="fadeInUp" data-delay="300"><span v-html="hero.titre"></span></h1>
            <p class="regular-text" data-inview="fadeInUp" data-delay="400">
                <span v-html="hero.texte"></span>
            </p>
        </article>
        <div class="image" data-inview="revealRight" data-delay="100">
            <img :src="hero.image.url" :alt="hero.image.titre" >
        </div>
    </header>
</template>

<script>
export default {
    name: 'SmallHero',

    props: {
        hero: {
            type: Object,
            required: true,
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
