<template>
    <div class="intro-titles">
        <div class="left">
            <h2 class="sup-title suptitle" data-inview="fadeInUp" data-delay="100">{{ block.surtitre }}</h2>
            <h3 class="small-title title" data-inview="fadeInUp" data-delay="300"><span v-html="block.titre"></span></h3>
            <div class="desc" data-inview="fadeInUp" data-delay="400" v-if="block.texte">
                <p class="small-text">
                    <span v-html="block.texte"></span>
                </p>
            </div>
        </div>
        <div class="right" data-inview="fadeInUp" data-delay="400">
            <router-link v-if="block.bouton.customText" :to="block.boutonUrl[$route.meta.lang] ? block.boutonUrl[$route.meta.lang] : block.boutonUrl" class="site-btn" :class="btn" :title="block.bouton.customText">{{ block.bouton.customText }}</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IntroTitles',

    props: {
        block: {
            type: Object,
            required: true,
        },
        btn: {
            type: String,
            required: false,
            default: '',
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
